import React, { useState } from 'react';
import axios from 'axios';
import { IMaskInput } from 'react-imask';
import { message } from 'react-message-popup';

import feedback from '../images/all-img/feedback.png';
import flagRus from '../images/icons/flag-rus.svg';
import { ENV } from '../common/constants.tsx';

const PHONE_DIGITS_COUNT = 10;

const Feedback: React.FC = () => {
  const [phone, setPhone] = useState<string>('');
  const [isSuccessfulShowing, setIsSuccessfulShowing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formattedPhone = phone.replace(/[^\d]/g, '');
  const isSubmitButtonActive = formattedPhone.length === PHONE_DIGITS_COUNT;

  const handleAccept = (value: string) => {
    setPhone(value)
  }

  const handleClick = async (event) => {
    event.preventDefault()

    try {
      setIsLoading(true);

      await axios.post(`${ENV.REACT_APP_OZON_WEB}/api/ozon/send-recall-email?phone=${formattedPhone}`);
      setIsSuccessfulShowing(true);
    } catch (error) {
      message.error('Ошибка', 4000).then(({ destory }) => {
        setTimeout(() => {
          destory()
        }, 2000)
      });
    } finally {
      setIsLoading(false);
    }
  }

  const handleClose = (event) => {
    event.preventDefault()
    setPhone('')
    setIsSuccessfulShowing(false)
  }

  return (
    <section className="feedback__section">
      <div className="container">
        {isSuccessfulShowing ?
          <div className='feedback__container feedback__container_success'>
            <div className="feedback__container-form">
              <h2 className="title">Мы записали ваш номер</h2>
              <p className="subtitle">Мы вам позвоним в течение часа</p>
              <form className="feedback__form">
                <button className="btn" onClick={handleClose}>Супер</button>
              </form>
            </div>
            <div className="feedback__image">
              <img src={feedback} loading="lazy" width={504} height={300} alt="feedback" />
            </div>
          </div>
          :
          <div className="feedback__container">
            <div className="feedback__container-form">
              <h2 className="title">Остались вопросы?</h2>
              <p className="subtitle">Мы вам позвоним в течение часа</p>
              <form className="feedback__form">

                <div className='feedback__input'>
                  <div className='feedback__code'>
                    <img src={flagRus} loading="lazy" width={18} height={18} alt="russia" />
                    +7
                  </div>
                  <IMaskInput
                    value={phone}
                    className="feedback__form-input"
                    placeholder="Номер телефона"
                    mask='000 000-00-00'
                    onAccept={handleAccept}
                  />
                </div>

                <button className="btn feedback__btn"
                  disabled={!isSubmitButtonActive}
                  onClick={handleClick}>
                  {isLoading ?
                    <div className="flex-center">
                      <span className="loader loader_small"></span>
                    </div> : 'Отправить'}
                </button>
              </form>
            </div>
            <div className="feedback__image">
              <img src={feedback} loading="lazy" width={504} height={300} alt="feedback" />
            </div>
          </div>}
      </div>
    </section >
  );
};

export default Feedback;
