import React from 'react';

const HowItWorksSection: React.FC = () => {
  return (
    <section className="how__section">
      <div className="container how__container">
        <h2 className="title how__title">Как оформить полис страхования</h2>
        <ul className="how__cards">
          <li className="how__block">
            <div className="icon-block how__icon-block">1</div>
            <div className="how__text-block">
              <h3 className="title">Рассчитайте<br />стоимость</h3>
              <p className="subtitle">Укажите число и города<br />сотрудников</p>
            </div>
          </li>
          <li className="how__block">
            <div className="icon-block how__icon-block">2</div>
            <div className="how__text-block">
              <h3 className="title">Настройте<br />тариф</h3>
              <p className="subtitle">Выберите услуги<br />и страховую компанию</p>
            </div>
          </li>
          <li className="how__block">
            <div className="icon-block how__icon-block">3</div>
            <div className="how__text-block">
              <h3 className="title">Заключите<br />договор</h3>
              <p className="subtitle">Свяжемся, чтобы<br />уточнить все детали</p>
            </div>
          </li>
          <li className="how__block">
            <div className="icon-block how__icon-block">4</div>
            <div className="how__text-block">
              <h3 className="title">Оплатите<br />счёт</h3>
              <p className="subtitle">Все документы пришлём<br />на электронную почту</p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default HowItWorksSection;
