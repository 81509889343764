import React, {
  ReactElement,
} from 'react';
import cross from '../../../images/icons/cross.svg';

import ReactModal from 'react-modal';

interface IModalProps {
  isOpen: boolean,
  children: ReactElement,
  onRequestClose: () => void,
}

const Modal: React.FC<IModalProps> = (props) => {
  const { isOpen, children, onRequestClose } = props;

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      width: '500px',
      padding: '48px',
      borderRadius: '20px'
    },
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      <div className="modal">
        <div className="modal__header">
          <button className="icon-block transparent" onClick={onRequestClose}>
            <img src={cross} alt="icon" />
          </button>
        </div>

        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
