import React from 'react';

import { ReactComponent as CheckIcon } from '../../images/icons/check.svg';
import { DisabledSteps } from './calculate';

export enum StepTypeEnum {
  price = "price",
  tariff = "tariff",
  // data = "data",
  contacts = "contacts"
}

export const STEPS_OPTIONS = [
  {
    index: 1,
    value: StepTypeEnum.price,
    label: 'Про коллектив',
  },
  {
    index: 2,
    value: StepTypeEnum.tariff,
    label: 'Тариф',
  },
  // {
  //   index: 3,
  //   value: StepTypeEnum.data,
  //   label: 'Данные',
  // },
  {
    index: 3,
    value: StepTypeEnum.contacts,
    label: 'Контакты',
  },
]

interface IProgressProps {
  stepOptions: Array<{ index: number; value: StepTypeEnum, label: string }>;
  disabledSteps: DisabledSteps
  onStepChange: (value: StepTypeEnum) => void,
}

const Progress: React.FC<IProgressProps> = (props) => {
  const {  stepOptions, disabledSteps, onStepChange } = props;

  const handleStepChange = (value: StepTypeEnum) => (event) => {
    event.preventDefault();

    onStepChange(value)
  }

  return (
    <div className="progress">
      <div className="progress__steps">
        {stepOptions.map((item) => {
          const isDisabled = disabledSteps[item.value].disabled;
          const isFinished = disabledSteps[item.value].finished;

          return <button
            key={item.value}
            className={`progress__step ${isDisabled ? '' : 'progress__step_active'}`}
            onClick={handleStepChange(item.value)}
            disabled={isDisabled}
          >
            {isFinished && <span><CheckIcon /></span>
            }
            {item.label}
          </button>
        })}
      </div>
    </div>
  )
};

export default Progress;
