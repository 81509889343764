import React from 'react';
import Modal from 'react-modal';
import Header from './components/header.tsx';
import MainSection from './components/main-section.tsx';
import HowItWorksSection from './components/how-it-works-section.tsx';
import Calculate from './components/calculate/calculate.tsx';
import Services from './components/services-widget.tsx';
import Trust from './components/trust.tsx';
import Advantages from './components/advantages.tsx';
import Answers from './components/answers.tsx';
import Feedback from './components/feedback.tsx';
import Footer from './components/footer.tsx';

import './scss/style.scss';

Modal.setAppElement('#root');

function App() {
  return (
    <>
      <Header />
      <main>
        <MainSection />
        <HowItWorksSection />
        <Calculate />
        <Services />
        <Trust />
        <Advantages />
        <Answers />
        <Feedback />
      </main>
      <Footer />
    </>
  );
}

export default App;
