import React from 'react';

import back from '../images/icons/back.svg';
import { StepTypeEnum } from './calculate/progress.tsx';

interface IProgressBackBtnProps {
  step: StepTypeEnum;
  onStepChange: (value: StepTypeEnum) => void;
}

const ProgressBackBtn = (props: IProgressBackBtnProps) => {
  const { step, onStepChange } = props;

  const getPreviousStep = () => {
    const stepKeys = Object.keys(StepTypeEnum);
    const stepIndex = stepKeys.indexOf(step);
    const previousStepIndex = stepIndex - 1;
    const previousStep = stepKeys[previousStepIndex] as StepTypeEnum;

    return previousStep;
  };

  const handleStepShange = (value: StepTypeEnum) => (event) => {
    event.preventDefault();

    onStepChange(value);
  };

  return (
        <button className="progress__back-btn">
          <img src={back} alt="back" onClick={handleStepShange(getPreviousStep())} />
        </button>
  );
};

export default ProgressBackBtn;
