import React from 'react';
import trust from '../images/all-img/trust.png';

const TariffWidget: React.FC = () => {
  return (
    <section className="trust__section">
      <div className="container">
        <div className="trust__container">
          <div className="trust__text-block">
            <h2 className="title trust__title">Выбирайте лучшие предложения<br />от ведущих страховых компаний</h2>
            <p className="subtitle trust__subtitle">Сотрудничаем только со страховщиками<br />с рейтингом ruA+</p>
          </div>
          <div className="trust__image">
            <img src={trust} width={476} height={308} loading="lazy" alt="icon" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TariffWidget;
