import React from 'react';

import shield from '../images/all-img/shield.png';
import coin from '../images/all-img/coin.png';
import advantages from '../images/all-img/advantages.png';
import advantagesMobile from '../images/all-img/advantages-mobile.png';

const Advantages: React.FC = () => {
  return (
    <section className="advantages__section">
      <div className="container advantages__container">
        <h2 className="title advantages__title">Доступная медицина для любого бизнеса </h2>
        <div className="advantages__cells">
          <div className="block advantages__cell">
            <div className="advanteges__text-block">
              <h3 className="title">Персональный подход</h3>
              <p className="subtitle">Обсудим ваши потребности и&nbsp;внесём их&nbsp;в&nbsp;договор</p>
            </div>
            <div className="advantages__image">
              <img src={shield} loading="lazy" width={110} height={126} alt="icon" />
            </div>
          </div>
          <div className="block advantages__cell">
            <div className="advantages__text-block">
              <h3 className="title">Гибкое решение</h3>
              <p className="subtitle">Условия под ваш бюджет, индивидуальный расчёт тарифа и&nbsp;дополнительные услуги для&nbsp;разных сотрудников</p>
            </div>
            <div className="advantages__image">
              <img src={coin} loading="lazy" width={140} height={140} alt="icon" />
            </div>
          </div>
          <div className="block block-lg advantages__cell">
            <div className="advantages__text-block">
              <h3 className="title">Цифровой портал ДМС</h3>
              <p className="subtitle">
                Корректируйте условия после оформления договора – онлайн в&nbsp;любое время
              </p>
            </div>
            <div className="advantages__image advantages__image_last">
              <img src={advantages} loading="lazy" width={362} alt="icon" />
            </div>
            <div className="advantages__image-mobile">
              <img src={advantagesMobile} loading="lazy" width={217} height={186} alt="icon" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
