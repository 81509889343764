import React from 'react';
import mainMobile from '../images/all-img/main-mobile.png';
import { ReactComponent as Нand } from '../images/icons/hand.svg';
import heart from '../images/icons/heart.svg';
import rocket from '../images/icons/rocket.svg';

const MainSection: React.FC = () => {

  const handleClick = () => {
    if (window) {
      const element = window.document.getElementById('calculate-section');

      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
        })
      }
    }
  }

  return (
    <section className="main__section">
      <div className="main__image"></div>
      <div className="container main__container">
        <div className="main__top">
          <div className="main__top-text-block">
            <h1 className="main__title">Медицинская<br />страховка для ваших<br />сотрудников</h1>
          </div>
          <div className="main__image-mobile">
            <img src={mainMobile} alt="main" />
          </div>
          <div className="main__bottom">
            <div className="main__block">
              <div className="main__icon-block">
                <Нand/>
              </div>
              <div className="main__text-block">
                <h3 className="title">Налоговые льготы</h3>
              </div>
            </div>
            <div className="main__block">
              <div className="main__icon-block">
                <img src={heart} alt="heart" />
              </div>
              <div className="main__text-block">
                <h3 className="title">Лояльность сотрудников</h3>
              </div>
            </div>
            <div className="main__block">
              <div className="main__icon-block">
                <img src={rocket} alt="rocket" />
              </div>
              <div className="main__text-block">
                <h3 className="title">Преимущества на рынке труда</h3>
              </div>
            </div>
          </div>
          <button className="btn main__btn" onClick={handleClick}>Рассчитать стоимость</button>
        </div>

      </div>
    </section>
  );
};

export default MainSection;
